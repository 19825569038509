import './App.css';
import {BrowserRouter as Router ,Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Service from './routes/Service';
import Contact from './routes/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        
      <Routes>
        <Route exact path='/' element={<Home />}/>
        <Route exact path='/about' element={ <About />}/>
        <Route exact path='/service' element={ <Service />}/>
        <Route exact path='/contact' element={  <Contact />}/>
   
      </Routes>
      </Router>
    </div>
  );
}

export default App;
