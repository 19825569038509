import React from 'react'
import "./ContactFormStyle.css"

const ContactForm = () => {
  return (
    <div className='form-container'>
      <h1>Send a message to us!</h1>
      <form >
        <input type="text" placeholder='Enter Your Name' />
        <input type="text" placeholder='Enter Your Email' />
        <input type="text" placeholder='Enter Your Subject' />
        <textarea rows="4" placeholder='Enter Your Message'></textarea>
        <button>Send Message</button>
      </form>
    </div>
  )
}

export default ContactForm
