import DestinationData from "./DestinationData";
import Mountain1 from "../assets/1.jpg";
import Mountain2 from "../assets/2.jpg";
import Mountain3 from "../assets/5.jpg";
import Mountain4 from "../assets/8.jpg";
const Destination = () => {
  return (
    <div className="destination">
      <h1>Popular Destination</h1>
      <p>Tours give you the opporunity</p>
      <DestinationData
        heading="Taal Volcano, Batangas"
        text="  Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Consequuntur nulla earum ullam. Ipsam temporibus, dolorum nemo illum
    dicta laudantium repellendus quia natus tenetur, beatae magnam?
    Explicabo nemo dolore odit sequi? Lorem ipsum dolor sit amet
    consectetur, adipisicing elit. Qui sit voluptates assumenda, placeat
    nam at explicabo esse aliquid ad incidunt dolore pariatur.
    Perspiciatis commodi facere alias tempore corrupti ipsum doloremque?"
        img1={Mountain1}
        img2={Mountain2}
      />

      <DestinationData
        className="first-des-reverse"
        heading="Mt. Daguldul, Batangas"
        text="  Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Consequuntur nulla earum ullam. Ipsam temporibus, dolorum nemo illum
    dicta laudantium repellendus quia natus tenetur, beatae magnam?
    Explicabo nemo dolore odit sequi? Lorem ipsum dolor sit amet
    consectetur, adipisicing elit. Qui sit voluptates assumenda, placeat
    nam at explicabo esse aliquid ad incidunt dolore pariatur.
    Perspiciatis commodi facere alias tempore corrupti ipsum doloremque?"
        img1={Mountain3}
        img2={Mountain4}
      />
    </div>
  );
};

export default Destination;
