import React from 'react'
import Navbar from '../component/Navbar'
import Hero from '../component/Hero'
import Night from "../assets/night.jpg"
import Footer from '../component/Footer'
import Aboutus from '../component/Aboutus'
const About = () => {
  return (
    <div>
      <Navbar />
      <Hero 
        cName = "hero-mid" 
        heroImg={Night}
        title="About"
        btnClass="hide"
        />
        <Aboutus />
        <Footer />
    </div>
  )
}

export default About
