import "./AboutusStyle.css";

const Aboutus = () => {
  return (
    <div className="about-container">
      <h1>Our History</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum sequi optio
        sunt saepe magni est suscipit cumque accusamus veritatis, hic sapiente,
        animi qui recusandae iure nemo pariatur quae ipsum consectetur. Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eos, voluptates
        repellat veniam, eligendi mollitia non numquam similique aliquam velit
        exercitationem soluta autem quibusdam asperiores nam officiis nemo
        voluptatum deserunt! Corrupti.
      </p>
      <h1>Our Mission</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum sequi optio
        sunt saepe magni est suscipit cumque accusamus veritatis, hic sapiente,
        animi qui recusandae iure nemo pariatur quae ipsum consectetur. Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eos, voluptates
        repellat veniam, eligendi mollitia non numquam similique aliquam velit
        exercitationem soluta autem quibusdam asperiores nam officiis nemo
        voluptatum deserunt! Corrupti.
      </p>
      <h1>Our Vision</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum sequi optio
        sunt saepe magni est suscipit cumque accusamus veritatis, hic sapiente,
        animi qui recusandae iure nemo pariatur quae ipsum consectetur. Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eos, voluptates
        repellat veniam, eligendi mollitia non numquam similique aliquam velit
        exercitationem soluta autem quibusdam asperiores nam officiis nemo
        voluptatum deserunt! Corrupti.
      </p>
    </div>
  );
};

export default Aboutus;
