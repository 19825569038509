import React from 'react'
import Navbar from '../component/Navbar'
import Hero from '../component/Hero'
import ContactImg from "../assets/2.jpg"
import Footer from '../component/Footer'
import ContactForm from '../component/ContactForm'
const Contact = () => {
  return (
    <div>
      <Navbar />
      <Hero 
        cName = "hero-mid" 
        heroImg={ContactImg}
        title="Contact"
        btnClass="hide"
        />
        <ContactForm/>
       <Footer />
    </div>
  )
}

export default Contact
