import "./TripStyle.css";
import TripData from "./TripData";
import Trip1 from "../assets/5.jpg";
import Trip2 from "../assets/8.jpg";
import Trip3 from "../assets/6.jpg";

const Trip = () => {
  return (
    <div className="trip">
      <h1>Recent Trips</h1>
      <p>You can discover unique destination using Google Maps</p>
    <div className="tripcard">
      <TripData
        img={Trip1}
        heading="Trip in Indonesia"
        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. 
        Veritatis assumenda odit consequuntur amet quos veniam iure. Voluptatibus cum repudiandae,
         eos animi temporibus eius aliquam facere ipsum et corporis consectetur facilis."
      />
       <TripData
        img={Trip2}
        heading="Trip in Malaysia"
        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. 
        Veritatis assumenda odit consequuntur amet quos veniam iure. Voluptatibus cum repudiandae,
         eos animi temporibus eius aliquam facere ipsum et corporis consectetur facilis."
      />
       <TripData
        img={Trip3}
        heading="Trip in London"
        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. 
        Veritatis assumenda odit consequuntur amet quos veniam iure. Voluptatibus cum repudiandae,
         eos animi temporibus eius aliquam facere ipsum et corporis consectetur facilis."
      />
      </div>
    </div>
  );
};

export default Trip;
